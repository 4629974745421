form.standart-form {
	max-width: 400px;
	text-align: center;
	.part {
		margin: 0 auto;
		display: inline-block;
		min-width: 300px;
	}
	.label {
		text-align: left;
		color: $form-label-color;
		font-family: $form-label-font;
		font-weight: 400;
		font-size: 15px;
		line-height: 22px;
	}
	input.name,
	input.email,
	input.phone {
		width: 300px;
		max-width: 300px;
		height: 45px;
		background-color: #fff;
		border: 1px solid #dcdcdc;
		border-radius: 3px;
		padding-left: 15px;
		font-family: $form-input-font;
		font-weight: 400;
		font-size: 16px;
		line-height: 45px;
		margin-bottom: 15px;
		display: block;
	}
}