.main-header {
	padding-top: 20px;
	min-height: 798px;
	position: relative;
	width: 100%;
	// background: url(../img/header.jpg) 50% 0 no-repeat;
	// -webkit-background-size: cover;
	//      -o-background-size: cover;
	//         background-size: cover;
	overflow: hidden;
	.video,
	.canvas {
		min-height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		min-width: 100%;
		background: #000;
		z-index: -1;
	}
	.logo {
		display: block;
		margin-bottom: 15px;
	}
	.date {
		color: #ffffff;
		font-family: Montserrat;
		font-size: 18px;
		font-weight: 400;
		line-height: 34px;
		text-transform: uppercase;
		display: inline-block;
		background-color: #3e5ffa;
		padding-left: 5px;
		padding-right: 5px;
		margin-bottom: 10px;
	}
	.title {
		color: #ffffff;
		font-family: Montserrat;
		font-size: 42px;
		font-weight: 700;
		line-height: 58px;
		text-transform: uppercase;
		span {
			margin-top: 10px;
			font-family: "Montserrat";
			font-size: 20px;
			font-weight: 600;
			line-height: 34px;
			text-transform: uppercase;
			display: block;
			margin-bottom: 32px;
		}
	}
	.content-block {
		padding-top: 55px;
	}
	.desc {
		color: #eeeeee;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 400;
		line-height: 30px;
		max-width: 380px;
		margin-bottom: 80px;
	}
	.bottom-text {
		color: #b3b3b3;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 400;
		line-height: 26px;
		max-width: 490px;
		padding-top: 25px;
		padding-bottom: 25px;
		padding-left: 90px;
		padding-right: 55px;
		background: url(../img/bottom-text-bg.png) 50%  no-repeat;
	}
	.form-block {
		background-color: #FFF;
		padding-top: 60px;
		padding-bottom: 45px;
		text-align: center;
		.form-title {
			color: #000000;
			font-family: "Montserrat";
			font-size: 17px;
			font-weight: 600;
			line-height: 24px;
			text-transform: uppercase;
			letter-spacing: 0.34px;
			span {
				margin-top: 20px;
				color: #000000;
				font-family: Montserrat;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				display: block;
				margin-bottom: 27px;
				text-transform: none;
			}
		}
		.form-bottom-text {
			color: #898989;
			font-family: Montserrat;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			margin-top: 35px;
		}
	}
}

.video-responsive {
    position: relative;
    width: 100%;
    background: url(../img/pattern.png);
}