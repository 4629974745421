/*********************************************\
		COLORS
\*********************************************/
$main-color: ;
$color1: ;
$color2: ;




$form-label-color: #000;

/*********************************************\
		FONTS
\*********************************************/
$form-label-font: Roboto;
$form-input-font: Roboto;