.sec1 {
	text-align: center;
	padding-top: 60px;
	background-color: #FFF;
	.sec-title {
		margin-bottom: 25px;
		opacity: 0.64;
		color: #000000;
		font-family: "Montserrat";
		font-size: 13px;
		font-weight: 400;
		line-height: 15px;
		text-transform: uppercase;
		letter-spacing: 2.08px;
	}
	.logos {
		height: 51px;
		background: url(../img/logos.png) 50% 0 no-repeat;
	}
}

.sec2 {
	padding-top: 125px;
	background-color: #FFF;
	.sec-title {
		color: #000000;
		font-family: Montserrat;
		font-size: 46px;
		font-weight: 700;
		line-height: 39px;
		position: relative;
		margin-bottom: 117px;
		z-index: 2;
		&:before {
			content:'';
			display: block;
			position: absolute;
			width: 178px;
			height: 144px;
			left: -50px;
			top: -59px;
			background: url(../img/check.svg) 50% no-repeat;
			z-index: -1;
		}
	}
	.item {
		padding-left: 110px;
		color: #1c1c1c;
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		max-width: 380px;
		background-repeat: no-repeat;
		background-position: 0 0;
		margin-bottom: 90px;
		min-height: 95px;
	}
	.it1 {background-image: url(../img/it1.svg);}
	.it2 {background-image: url(../img/it2.svg);}
	.it3 {background-image: url(../img/it3.svg);}
	.it4 {background-image: url(../img/it4.svg);}
}

.sec3 {
	padding-top: 100px;
	padding-bottom: 90px;
	.sec-title {
		color: #000000;
		font-family: Montserrat;
		font-size: 46px;
		font-weight: 700;
		line-height: 39px;
		position: relative;
		margin-bottom: 110px;
		&:before {
			content: '';
			display: block;
			width: 169px;
			height: 169px;
			position: absolute;
			left: -20px;
			top: -64px;
			background: url(../img/krest.png) 0 0 no-repeat;
		}
	}
	.item {
		padding-top: 180px;
		background-repeat: no-repeat;
		background-position: 0 0;
		margin-bottom: 20px;
		.item-title {
			color: #000000;
			font-family: Montserrat;
			font-size: 22px;
			font-weight: 700;
			line-height: 30px;
			margin-bottom: 20px;
		}
		.item-text {
			color: #1c1c1c;
			font-family: Montserrat;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}
	}
	.it1 {background-image: url(../img/s3it1.svg);}
	.it2 {background-image: url(../img/s3it2.svg);}
	.it3 {background-image: url(../img/s3it3.svg);}
	.btn {
		margin-top: 100px;
	}
}

.sec4 {
	text-align: center;
	padding-top: 100px;
	padding-bottom: 100px;
	background: url(../img/sec4.jpg) 50% 0 no-repeat;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	.sec-title {
		color: #ffffff;
		font-family: Montserrat;
		font-size: 32px;
		font-weight: 700;
		line-height: 55px;
		margin-bottom: 80px;
	}
	.day {
		max-width: 780px;
		margin: 0 auto;
		background-color: #FFF;
		padding-top: 50px;
		padding-left: 65px;
		padding-right: 45px;
		padding-bottom: 40px;
		text-align: left;
		margin-bottom: 35px;
		.date {
			opacity: 0.99;
			color: #000000;
			font-family: "Montserrat";
			font-size: 15px;
			font-weight: 600;
			line-height: 30px;
			text-transform: uppercase;
			margin-bottom: 25px;
			span {
				display: inline-block;
				letter-spacing: 3px;
				background-color: #ececec;
				padding-left: 10px;
				padding-right: 10px;
				margin-right: 14px;
			}
		}
		.session-number {
			text-align: center;
			display: inline-block;
			vertical-align: top;
			color: #9f44e9;
			font-family: Montserrat;
			font-size: 15px;
			font-weight: 600;
			line-height: 30px;
			text-transform: uppercase;
			letter-spacing: 3px;
			margin-right: 55px;
			span {
				margin-top: 5px;
				display: block;
				font-size: 55px;
				line-height: 55px;
			}
		}
		.session-title {
			display: inline-block;
			vertical-align: top;
			width: 100%;
			max-width: 455px;
			color: #000000;
			font-family: Montserrat;
			font-size: 30px;
			font-weight: 700;
			line-height: 41px;
			margin-bottom: 50px;
		}
		ul {
			margin: 0;
			padding: 0;
			max-width: 560px;
			li {
				padding-left: 58px;
				color: #000000;
				font-family: Montserrat;
				font-size: 16px;
				font-weight: 400;
				line-height: 27px;
				font-weight: 400;
				margin-bottom: 30px;
				background: url(../img/bull.svg) 0 4px no-repeat;
				b {
					font-weight: 700;
				}
			}
		}
	}
	.btn {
		width: 100%;
		max-width: 780px;
	}
}

.sec5 {
	padding-top: 100px;
	padding-bottom: 156px;
	.sec-title {
		text-align: center;
		color: #000000;
		font-family: Montserrat;
		font-size: 36px;
		font-weight: 700;
		line-height: 50px;
		margin-bottom: 90px;
	}
	.block {
		margin-bottom: 116px;
		.block-number {
			opacity: 0.23;
			color: #000000;
			font-family: Montserrat;
			font-size: 42px;
			font-weight: 400;
			line-height: 30px;
			margin-bottom: 30px;
		}
		.block-title {
			color: #000000;
			font-family: Montserrat;
			font-size: 22px;
			font-weight: 700;
			line-height: 30px;
			margin-bottom: 30px;
		}
		.block-text {
			color: #1c1c1c;
			font-family: Montserrat;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}
		.block-image {
			height: 360px;
			background: url(../img/bi1.jpg) 50% 0 no-repeat;
		}
	}
	.block2 {
		.video {
			display: block;
			height: 275px;
			width: 492px;
		}
	}
	.block3 {
		margin-bottom: 65px;
		.bi3 {
			background: url(../img/bi3.png) 0 0 no-repeat;
			height: 321px;
		}
	}
}

.sec6 {
	background: url(../img/sec6.jpg) 50% 0 no-repeat;
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	padding-bottom: 60px;
	.infoblock {
		background-color: #FFF;
		max-width: 490px;
		width: 100%;
		display: inline-block;
		padding-top: 55px;
		padding-bottom: 75px;
		padding-left: 65px;
		padding-right: 65px;
		box-shadow: 0 13px 14px 10px rgba(0, 0, 0, 0.08);
		transform: translateY(-60px);
		.block-title {
			color: #1c1c1c;
			font-family: Montserrat;
			font-size: 18px;
			font-weight: 400;
			line-height: 28px;
			margin-bottom: 10px;
		}
		.oles-name {
			color: #000000;
			font-family: Montserrat;
			font-size: 34px;
			font-weight: 700;
			line-height: 58px;
			text-transform: uppercase;
			margin-bottom: 30px;
		}
		ul {
			margin: 0;
			padding: 0;
			li {
				color: #141414;
				font-family: "OpenSans";
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				margin-bottom: 36px;
				position: relative;
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 12px;
					left: -20px;
					width: 11px;
					height: 1px;
					background-color: #9a9a9a;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.videobtn {
		padding-left: 20px;
		border-radius: 32px;
		background-color: #4e56f9;
		background-repeat: no-repeat;
		background-position: 31px 50%;
		background-image: url(../img/play.svg);
		display: inline-block;
		vertical-align: top;
		width: 390px;
		text-align: center;
		color: #ffffff;
		font-family: Roboto;
		font-size: 14px;
		font-weight: 400;
		line-height: 64px;
		float: right;
		margin-top: 318px;
		span {
			color: #ffffff;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 1.4px;
			margin-right: 25px;
		}
	}
}