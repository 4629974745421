.modal-overlay {
	display: none;
	padding-top: 1px;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0,0,0,0.5);
	z-index: 1000000;
	overflow: auto;
	.modal-content {
		margin: 15vh auto;
		width: 960px;
		padding:0;
		background:transparent;
		margin-bottom: 0px;
		border-radius: 4px;
		height: 480px;
	}
	.close-button {
		position: absolute;
		width: 50px;
		height: 50px;
		top: 30px;
		right: 40px;
		padding: 0px 14px;
		padding-top: 4px;
		cursor: pointer;
		transition: 0.2s linear;
		.one {
			height: 50px;
			width: 2px;
			background-color: #fff;
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transition: 0.2s linear; 
		}
		.two {
			margin-top: -50px;
			height: 50px;
			width: 2px;
			background-color: #fff;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transition: 0.2s linear; 
		}
		&:hover {
			.one {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transition: 0.2s linear;
				background-color: #000;
			}
			.two {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transition: 0.2s linear;
				background-color: #000;
			}
		}
	}
}
