.subm {
	cursor: pointer;
	border: none;
	display: block;
	margin: 0 auto;
	background-color: #2266f6;
	width: 300px;
	color: #ffffff;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	line-height: 74px;
	background-image: linear-gradient(-77deg, #ff29d8 0%, #2068ff 93%, #2068ff 100%);
	transition: all 0.3s linear;
	margin-top: 30px;
	margin-bottom: 35px;
	&:hover,
	&:focus {
		transform: scale(1.02);
	}
}


.btn {
	text-align: center;
	display: block;
	margin: 0 auto;
	background-color: #2266f6;
	width: 300px;
	color: #ffffff;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	line-height: 74px;
	background-image: linear-gradient(-77deg, #ff29d8 0%, #2068ff 93%, #2068ff 100%);
}
