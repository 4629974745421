@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, video {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*********************************************		COLORS
\*********************************************/
/*********************************************		FONTS
\*********************************************/
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/RobotoRegular/RobotoRegular.eot");
  src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"), url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype"), url("../fonts/RobotoRegular/RobotoRegular.svg#Roboto") format("svg"); }

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoMedium/RobotoMedium.eot");
  src: url("../fonts/RobotoMedium/RobotoMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMedium/RobotoMedium.woff") format("woff"), url("../fonts/RobotoMedium/RobotoMedium.ttf") format("truetype"), url("../fonts/RobotoMedium/RobotoMedium.svg#Roboto") format("svg"); }

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/RobotoBold/RobotoBold.eot");
  src: url("../fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/RobotoBold.woff") format("woff"), url("../fonts/RobotoBold/RobotoBold.ttf") format("truetype"), url("../fonts/RobotoBold/RobotoBold.svg#Roboto") format("svg"); }

@font-face {
  font-family: "OpenSans";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/OpenSansRegular/OpenSansRegular.eot");
  src: url("../fonts/OpenSansRegular/OpenSansRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansRegular/OpenSansRegular.woff") format("woff"), url("../fonts/OpenSansRegular/OpenSansRegular.ttf") format("truetype"), url("../fonts/OpenSansRegular/OpenSansRegular.svg#OpenSans") format("svg"); }

body {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  min-width: 320px;
  color: #333333;
  position: relative;
  background-color: #ffffff; }

.container {
  width: 960px; }

*,
*:before,
*:after {
  box-sizing: border-box; }

.hidden {
  display: none; }

.wrapper {
  margin: 0 auto; }

a {
  text-decoration: none; }

form input.error {
  box-shadow: inset 0px 0px 20px 1px rgba(255, 0, 0, 0.3);
  border: 2px solid red !important; }

form input.not_error {
  box-shadow: inset 0px 0px 20px 1px rgba(100, 255, 100, 0.3);
  border: 2px solid #99FF99 !important; }

.modal-overlay {
  display: none;
  padding-top: 1px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  overflow: auto; }
  .modal-overlay .modal-content {
    margin: 15vh auto;
    width: 960px;
    padding: 0;
    background: transparent;
    margin-bottom: 0px;
    border-radius: 4px;
    height: 480px; }
  .modal-overlay .close-button {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 30px;
    right: 40px;
    padding: 0px 14px;
    padding-top: 4px;
    cursor: pointer;
    transition: 0.2s linear; }
    .modal-overlay .close-button .one {
      height: 50px;
      width: 2px;
      background-color: #fff;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transition: 0.2s linear; }
    .modal-overlay .close-button .two {
      margin-top: -50px;
      height: 50px;
      width: 2px;
      background-color: #fff;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transition: 0.2s linear; }
    .modal-overlay .close-button:hover .one {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transition: 0.2s linear;
      background-color: #000; }
    .modal-overlay .close-button:hover .two {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transition: 0.2s linear;
      background-color: #000; }

/*Параметры контейнера */
.countdownHolder {
  width: 450px;
  margin: 0 auto;
  font: 30px/1.5 Roboto, sans-serif;
  text-align: center;
  letter-spacing: -3px;
  /*Параметры цифр */
  /*Параметры подложки для секций */
  /*Параметры секций разделения (:)*/
  /*Параметры вспомогательного текста (дни, часы, минуты, секунды)*/ }
  .countdownHolder .position {
    display: inline-block;
    height: 1.7em;
    overflow: hidden;
    position: relative;
    width: 0.55em; }
  .countdownHolder .digit {
    position: absolute;
    display: block;
    width: 0.5em;
    border-radius: 0.2em;
    text-align: center;
    color: #000;
    letter-spacing: -1px; }
    .countdownHolder .digit.static {
      margin-top: 18px; }
  .countdownHolder .coundDays,
  .countdownHolder .countHours,
  .countdownHolder .countMinutes,
  .countdownHolder .countSeconds {
    background: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 5px; }
  .countdownHolder .countDiv {
    display: none;
    width: 16px;
    height: 1.6em;
    position: relative; }
    .countdownHolder .countDiv:before, .countdownHolder .countDiv:after {
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: #444;
      border-radius: 50%;
      left: 50%;
      margin-left: -3px;
      top: 0.5em;
      box-shadow: 1px 1px 1px rgba(4, 4, 4, 0.5);
      content: ''; }
    .countdownHolder .countDiv:after {
      top: 0.9em; }
  .countdownHolder .textdigit {
    font-size: 15px;
    font-family: Roboto;
    letter-spacing: 0px; }

.subm {
  cursor: pointer;
  border: none;
  display: block;
  margin: 0 auto;
  background-color: #2266f6;
  width: 300px;
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  line-height: 74px;
  background-image: linear-gradient(-77deg, #ff29d8 0%, #2068ff 93%, #2068ff 100%);
  transition: all 0.3s linear;
  margin-top: 30px;
  margin-bottom: 35px; }
  .subm:hover, .subm:focus {
    transform: scale(1.02); }

.btn {
  text-align: center;
  display: block;
  margin: 0 auto;
  background-color: #2266f6;
  width: 300px;
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  line-height: 74px;
  background-image: linear-gradient(-77deg, #ff29d8 0%, #2068ff 93%, #2068ff 100%); }

.main-header {
  padding-top: 20px;
  min-height: 798px;
  position: relative;
  width: 100%;
  overflow: hidden; }
  .main-header .video,
  .main-header .canvas {
    min-height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    min-width: 100%;
    background: #000;
    z-index: -1; }
  .main-header .logo {
    display: block;
    margin-bottom: 15px; }
  .main-header .date {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    text-transform: uppercase;
    display: inline-block;
    background-color: #3e5ffa;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px; }
  .main-header .title {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 42px;
    font-weight: 700;
    line-height: 58px;
    text-transform: uppercase; }
    .main-header .title span {
      margin-top: 10px;
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: 600;
      line-height: 34px;
      text-transform: uppercase;
      display: block;
      margin-bottom: 32px; }
  .main-header .content-block {
    padding-top: 55px; }
  .main-header .desc {
    color: #eeeeee;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    max-width: 380px;
    margin-bottom: 80px; }
  .main-header .bottom-text {
    color: #b3b3b3;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    max-width: 490px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 90px;
    padding-right: 55px;
    background: url(../img/bottom-text-bg.png) 50% no-repeat; }
  .main-header .form-block {
    background-color: #FFF;
    padding-top: 60px;
    padding-bottom: 45px;
    text-align: center; }
    .main-header .form-block .form-title {
      color: #000000;
      font-family: "Montserrat";
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
      letter-spacing: 0.34px; }
      .main-header .form-block .form-title span {
        margin-top: 20px;
        color: #000000;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        display: block;
        margin-bottom: 27px;
        text-transform: none; }
    .main-header .form-block .form-bottom-text {
      color: #898989;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 35px; }

.video-responsive {
  position: relative;
  width: 100%;
  background: url(../img/pattern.png); }

form.standart-form {
  max-width: 400px;
  text-align: center; }
  form.standart-form .part {
    margin: 0 auto;
    display: inline-block;
    min-width: 300px; }
  form.standart-form .label {
    text-align: left;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px; }
  form.standart-form input.name,
  form.standart-form input.email,
  form.standart-form input.phone {
    width: 300px;
    max-width: 300px;
    height: 45px;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    padding-left: 15px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 45px;
    margin-bottom: 15px;
    display: block; }

.sec1 {
  text-align: center;
  padding-top: 60px;
  background-color: #FFF; }
  .sec1 .sec-title {
    margin-bottom: 25px;
    opacity: 0.64;
    color: #000000;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 2.08px; }
  .sec1 .logos {
    height: 51px;
    background: url(../img/logos.png) 50% 0 no-repeat; }

.sec2 {
  padding-top: 125px;
  background-color: #FFF; }
  .sec2 .sec-title {
    color: #000000;
    font-family: Montserrat;
    font-size: 46px;
    font-weight: 700;
    line-height: 39px;
    position: relative;
    margin-bottom: 117px;
    z-index: 2; }
    .sec2 .sec-title:before {
      content: '';
      display: block;
      position: absolute;
      width: 178px;
      height: 144px;
      left: -50px;
      top: -59px;
      background: url(../img/check.svg) 50% no-repeat;
      z-index: -1; }
  .sec2 .item {
    padding-left: 110px;
    color: #1c1c1c;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-width: 380px;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-bottom: 90px;
    min-height: 95px; }
  .sec2 .it1 {
    background-image: url(../img/it1.svg); }
  .sec2 .it2 {
    background-image: url(../img/it2.svg); }
  .sec2 .it3 {
    background-image: url(../img/it3.svg); }
  .sec2 .it4 {
    background-image: url(../img/it4.svg); }

.sec3 {
  padding-top: 100px;
  padding-bottom: 90px; }
  .sec3 .sec-title {
    color: #000000;
    font-family: Montserrat;
    font-size: 46px;
    font-weight: 700;
    line-height: 39px;
    position: relative;
    margin-bottom: 110px; }
    .sec3 .sec-title:before {
      content: '';
      display: block;
      width: 169px;
      height: 169px;
      position: absolute;
      left: -20px;
      top: -64px;
      background: url(../img/krest.png) 0 0 no-repeat; }
  .sec3 .item {
    padding-top: 180px;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-bottom: 20px; }
    .sec3 .item .item-title {
      color: #000000;
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 20px; }
    .sec3 .item .item-text {
      color: #1c1c1c;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; }
  .sec3 .it1 {
    background-image: url(../img/s3it1.svg); }
  .sec3 .it2 {
    background-image: url(../img/s3it2.svg); }
  .sec3 .it3 {
    background-image: url(../img/s3it3.svg); }
  .sec3 .btn {
    margin-top: 100px; }

.sec4 {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background: url(../img/sec4.jpg) 50% 0 no-repeat;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .sec4 .sec-title {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 700;
    line-height: 55px;
    margin-bottom: 80px; }
  .sec4 .day {
    max-width: 780px;
    margin: 0 auto;
    background-color: #FFF;
    padding-top: 50px;
    padding-left: 65px;
    padding-right: 45px;
    padding-bottom: 40px;
    text-align: left;
    margin-bottom: 35px; }
    .sec4 .day .date {
      opacity: 0.99;
      color: #000000;
      font-family: "Montserrat";
      font-size: 15px;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;
      margin-bottom: 25px; }
      .sec4 .day .date span {
        display: inline-block;
        letter-spacing: 3px;
        background-color: #ececec;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 14px; }
    .sec4 .day .session-number {
      text-align: center;
      display: inline-block;
      vertical-align: top;
      color: #9f44e9;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-right: 55px; }
      .sec4 .day .session-number span {
        margin-top: 5px;
        display: block;
        font-size: 55px;
        line-height: 55px; }
    .sec4 .day .session-title {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      max-width: 455px;
      color: #000000;
      font-family: Montserrat;
      font-size: 30px;
      font-weight: 700;
      line-height: 41px;
      margin-bottom: 50px; }
    .sec4 .day ul {
      margin: 0;
      padding: 0;
      max-width: 560px; }
      .sec4 .day ul li {
        padding-left: 58px;
        color: #000000;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        font-weight: 400;
        margin-bottom: 30px;
        background: url(../img/bull.svg) 0 4px no-repeat; }
        .sec4 .day ul li b {
          font-weight: 700; }
  .sec4 .btn {
    width: 100%;
    max-width: 780px; }

.sec5 {
  padding-top: 100px;
  padding-bottom: 156px; }
  .sec5 .sec-title {
    text-align: center;
    color: #000000;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 90px; }
  .sec5 .block {
    margin-bottom: 116px; }
    .sec5 .block .block-number {
      opacity: 0.23;
      color: #000000;
      font-family: Montserrat;
      font-size: 42px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 30px; }
    .sec5 .block .block-title {
      color: #000000;
      font-family: Montserrat;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 30px; }
    .sec5 .block .block-text {
      color: #1c1c1c;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; }
    .sec5 .block .block-image {
      height: 360px;
      background: url(../img/bi1.jpg) 50% 0 no-repeat; }
  .sec5 .block2 .video {
    display: block;
    height: 275px;
    width: 492px; }
  .sec5 .block3 {
    margin-bottom: 65px; }
    .sec5 .block3 .bi3 {
      background: url(../img/bi3.png) 0 0 no-repeat;
      height: 321px; }

.sec6 {
  background: url(../img/sec6.jpg) 50% 0 no-repeat;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-bottom: 60px; }
  .sec6 .infoblock {
    background-color: #FFF;
    max-width: 490px;
    width: 100%;
    display: inline-block;
    padding-top: 55px;
    padding-bottom: 75px;
    padding-left: 65px;
    padding-right: 65px;
    box-shadow: 0 13px 14px 10px rgba(0, 0, 0, 0.08);
    transform: translateY(-60px); }
    .sec6 .infoblock .block-title {
      color: #1c1c1c;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 10px; }
    .sec6 .infoblock .oles-name {
      color: #000000;
      font-family: Montserrat;
      font-size: 34px;
      font-weight: 700;
      line-height: 58px;
      text-transform: uppercase;
      margin-bottom: 30px; }
    .sec6 .infoblock ul {
      margin: 0;
      padding: 0; }
      .sec6 .infoblock ul li {
        color: #141414;
        font-family: "OpenSans";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 36px;
        position: relative; }
        .sec6 .infoblock ul li:before {
          content: "";
          display: block;
          position: absolute;
          top: 12px;
          left: -20px;
          width: 11px;
          height: 1px;
          background-color: #9a9a9a; }
        .sec6 .infoblock ul li:last-child {
          margin-bottom: 0; }
  .sec6 .videobtn {
    padding-left: 20px;
    border-radius: 32px;
    background-color: #4e56f9;
    background-repeat: no-repeat;
    background-position: 31px 50%;
    background-image: url(../img/play.svg);
    display: inline-block;
    vertical-align: top;
    width: 390px;
    text-align: center;
    color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 64px;
    float: right;
    margin-top: 318px; }
    .sec6 .videobtn span {
      color: #ffffff;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      margin-right: 25px; }

.footer1,
.footer2,
.footer3 {
  vertical-align: top;
  display: inline-block; }

footer {
  font-family: Roboto;
  font-weight: 500;
  vertical-align: top;
  padding-bottom: 35px;
  width: 100%;
  min-height: 250px;
  background-color: #fff;
  text-align: center; }

footer br.sm {
  display: none; }

footer br.xs {
  display: none; }

footer p {
  color: #363636;
  font-size: 13px;
  line-height: 20px;
  text-align: left; }

footer .social {
  margin-bottom: 30px;
  text-align: center; }

footer a {
  padding: 0 10px;
  padding-left: 0px;
  color: #363636;
  font-size: 13px;
  transition: all 0.3s linear;
  text-align: left; }

footer a:hover {
  text-decoration: none; }

footer a.partners {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  width: 250px;
  height: 50px;
  line-height: 50px;
  color: #f45c33;
  border: 1px solid #f45c33; }

footer a.partners:hover {
  color: #fff;
  background-color: #f45c33; }

footer .contacts {
  margin-top: 0; }

footer .copy {
  margin-top: 44px;
  margin-bottom: 0; }

footer .icon-soc {
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: top;
  margin: 0px 20px;
  background: #464646;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  padding: 0px; }
  footer .icon-soc i {
    text-align: center;
    font-size: 20px;
    line-height: 35px;
    margin: 0 auto; }

footer a.icon-soc:hover {
  opacity: 0.8; }

footer .icon-fb {
  margin-left: 0px; }

footer .icon-yt {
  margin-right: 0px; }

footer .modal-btn {
  padding-left: 20px;
  display: block;
  margin-top: 0px;
  margin-bottom: 14px; }

footer .modal-btn:before {
  content: '\f00c';
  font-family: FontAwesome;
  margin-left: -10px;
  margin-right: 10px;
  color: #6895b7; }

.footer2 {
  padding-top: 60px;
  margin-left: 30px; }

.footer3 {
  padding-top: 58px;
  margin-left: 30px; }

.footer3 p.text4 {
  line-height: 25px;
  text-align: right;
  font-family: Roboto;
  font-weight: 400; }

.footer3 p.text4 span a {
  font-size: 16px;
  padding-right: 70px;
  float: left;
  font-family: Roboto;
  font-weight: 700; }

.footer-line {
  display: inline-block;
  margin-top: 35px;
  margin-bottom: 35px;
  width: 90%;
  height: 1px;
  background-color: #6c6c6c;
  opacity: 0.3; }

footer .bottom-text {
  font-size: 13px;
  line-height: 22px;
  text-align: center; }

footer a.mail {
  background-color: #f8ff44;
  color: #636475;
  font-weight: 700; }

@media only screen and (max-width: 1130px) {
  footer .footer3 {
    margin-left: 0px; } }

@media only screen and (max-width: 720px) {
  footer .copy {
    margin: 24px auto;
    display: block; }
  .footer2 {
    padding-top: 0px;
    margin: 0 auto;
    display: block; }
  footer p {
    color: #979898;
    font-size: 13px;
    text-align: center; }
  footer a.icon-soc {
    display: inline-block;
    width: 35px;
    height: 35px;
    vertical-align: top;
    margin: 0px 10px; }
  .footer3 p.text4 span {
    color: #636475;
    font-size: 16px;
    padding-right: 20px; }
  .footer-inline {
    padding-left: 50px;
    padding-top: 20px; }
  .footer3 {
    padding-top: 38px;
    margin-left: 0px; }
  footer .bottom-text br {
    display: none; } }
